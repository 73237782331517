.aboutus-cont{
    margin-top: 12rem;
    display: flex;
    width: 100%;
}
.aboutus-l-s{
    width: 45rem;
    position: relative;
}
.shape-three{
    width: 50rem;
    height: 50rem;
    z-index: -10;
    position: absolute;
    top: 0;
    left: 0;
    transform:translatex(-5.5rem) translateY(-15rem) rotate(129deg);
}
.aboutus-image{
    width: 30rem;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(4rem) translateY(-1rem);
}
.aboutus-r-s{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.aboutus-list-cont{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.aboutus-list{
    list-style: none;
    display: flex; 
    gap: .5rem;
    align-items: center;
}
.aboutus-icon{
    min-width: 32px;
    min-height: 20px;
    fill: var(--mainColor);
}
@media screen and (max-width : 1100px){
    .aboutus-cont{
        margin-top: 5rem;
        flex-direction:column-reverse;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: 90%;
    }
    .aboutus-l-s{
        width: fit-content;
        margin: auto;
        margin-top: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .shape-three{
        position: relative;
        width: 125vw;
        margin-left: -6vw;
    }
    .aboutus-r-s{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 10rem;
    }
    .aboutus-image{
        width: 70vw;
        position: absolute;
        left: -4rem;
        top: 4.5rem;
        transform: translateX(4rem) translateY(-1rem);
    }
    .aboutus-list{
        align-items: flex-start;
    }
}

