.sevices-cont{
    margin: auto;
    margin-top: 15rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    max-width: 1300px;
}
.services-table{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.services-table>:nth-child(1){
    border-top: none;
    border-left: none;
}
.services-table>:nth-child(2){
    border-top: none;
}
.services-table>:nth-child(3){
    border-top: none;
    border-right: none;
}
.services-table>:nth-child(4){
    border-bottom: none;
    border-left: none;
}
.services-table>:nth-child(5){
    border-bottom: none;
}
.services-table>:nth-child(6){
    border-bottom: none;
    border-right: none;
}
@media screen and (max-width:1220px) {
    .services-table>:nth-child(1){
        border: 1px solid rgba(0, 0, 0, .1);
        border-top: none;
        border-left: none;
    }
    .services-table>:nth-child(2){
        border: 1px solid rgba(0, 0, 0, .1);
        border-top: none;
        border-right: none;
    }
    .services-table>:nth-child(3){
        border: 1px solid rgba(0, 0, 0, .1);
        border-left: none;
    }
    .services-table>:nth-child(4){
        border: 1px solid rgba(0, 0, 0, .1);
        border-right: none;
    }
    .services-table>:nth-child(5){
        border: 1px solid rgba(0, 0, 0, .1);
        border-bottom: none;
        border-left: none;
    }
    .services-table>:nth-child(6){
        border: 1px solid rgba(0, 0, 0, .1);
        border-bottom: none;
        border-right: none;
    }
}
@media screen and (max-width:855px){
    .services-table>:nth-child(n){
        border: none;
        border-bottom: 2px solid rgba(0, 0, 0, .1);
    }
    .services-table>:nth-child(6){
        border: none;
    }
}
.services-card{
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 2rem 1rem;
}
.services-table-icon{
    width: 6rem;
}
.services-head{
    font-weight: 500;
    letter-spacing: 1px;
}
.servises-paragraph{
    text-align: center;
}
.side-shapes{
    width: 100vw;
}
.side-shapes>:nth-child(n){
    width: 8rem;
    transform: rotate(20deg);
    position: absolute;
    top: 94rem;
}
.side-shapes>:nth-child(1){
    width: 14rem;
    right: 0;
    transform:translateX(6.7rem) translateY(-9.7rem) rotate(12deg) scaleY(-1);
}
.side-shapes>:nth-child(2){
    width: 10rem;
    right: 0;
    transform:translateX(3.2rem) translateY(-9rem);
}
.side-shapes>:nth-child(3){
    width: 14rem;
    left: 0;
    transform:translateX(-6rem) translateY(3.1rem) rotate(-12deg) scaleY(-1) scaleX(-1);
}
.side-shapes>:nth-child(4){
    width: 10rem;
    left: 0;
    transform:translateX(-2.5rem) translateY(4rem) scaleX(-1);
}
@media screen and (max-width : 1100px){
    .sevices-cont{
        margin-top: -15rem;
    }
    .side-shapes>:nth-child(1){
    width: 14rem;
    right: 0;
    transform:translateX(6.7rem) translateY(30.7rem) rotate(12deg) scaleY(-1);
}
.side-shapes>:nth-child(2){
    width: 10rem;
    right: 0;
    transform:translateX(3.2rem) translateY(31.5rem);
}
.side-shapes>:nth-child(3){
    display: none;
}
.side-shapes>:nth-child(4){
    display: none;
}
}