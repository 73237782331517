.portfolio-cont{
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    text-align: center;
    position: relative;
}
.characters-cont{
    display: flex;
    margin-top: 5rem;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.portfolio-card{
    display: flex;
    flex-direction: column;
    gap: .4rem;
}
.portfolio-card>div{
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}
.portfolio-card>div>img{
    width: 100%;
    height: 100%;
}
.portfolio-card>div>div{
    width: 100%;
    height: 100%;
    background-color:var(--secondaryColor);
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
}
.portfolio-card>div>div>:nth-child(n){
    fill:#ffffff;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
}
.portfolio-card>div>div:hover{
    opacity: .8;
    transition: 1s;
    gap: 1rem;
}
.portfolio-card>h3{
    font-size: 1.3rem;
}
.portfolio-card>p{
    font-size: .9rem;
}
.zigzag-line{
    position: absolute;
    z-index: -9;
    top: 0;
    left: -2rem;
    width: 100vw;
    max-width: 1400px;
    height: 35rem;
}
@media screen and (max-width : 1100px){
    .characters-cont{
        flex-direction: column;
        align-items: center;
        gap: 5rem;
    }
    .zigzag-line{
        display: none;
    }
    .characters-cont{
        margin-top: 2rem;
    }
}