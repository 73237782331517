.footer-cont{
    margin-top: 10rem;
    position: relative;
    overflow-y: hidden;
    height: 15rem;
    display: flex;
    gap: 2rem;
}
.footer-l-s{
    display: flex;
    width: 50%;
    padding-top: 3rem;
}
.footer-l-s>:nth-child(1){
    width: 85%;
}
.footer-l-s>:nth-child(2){
    width: 30%;
    position: relative;
}
.footer-main-head{
    font-weight: 400;
}
.footer-arrow{
    fill: var(--secondaryColor);
    width: 2rem;
}
.arrow-cont{
    position: absolute;
    top: 2.8rem;
    left: -2rem;
}
.footer-l-s>div>div{
    display: flex;
    align-items: center;
}
.footer-l-s>div>div>h1{
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 800;
    letter-spacing: .1rem;
}
.footer-l-s>div>div>img{
    color: #ffffff;
    width: 3.2rem;
}
.footer-l-s>div{
    display: flex;
    flex-direction: column;
    line-height: 1.5rem;
    gap: 1rem;
}
.footer-l-s>:nth-child(1)>p{
    color: #ffffff;
    width: 70%;
}
.footer-r-s{
    display: flex;
    justify-content: space-between;
    width: 50%;
    padding-top: 2.8rem;
    padding-left: 5rem;
}
.footer-r-s>:nth-child(1){
    width: 35%;
}
.footer-r-s>:nth-child(2){
    width: 45%;
}
.mail-box{
    border-bottom: 2px solid #000000;
    width: fit-content;
    display: flex;
    align-items: center;
}
.mail-box>input{
    border: none;
    padding: 1rem;
    font-size: 1.1rem;
}
.mail-box>input:focus{
    border: none;
}
.te-icon{
    width: 2rem;
    height: 2rem;
    fill: var(--secondaryColor);
}
.footer-r-s-icons-cont{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
}
.footer-r-s-icons-cont>:nth-child(n){
    width: 1.5rem;
    height: 1.5rem;
    color: var(--secondaryColor);
    cursor: pointer;
}
@media screen and (max-width : 1100px){

    .footer-cont{
        flex-direction: column-reverse;
        height: auto;
        overflow-x: hidden;
    }
    .footer-l-s{
        flex-direction: column-reverse;
        width: 100%;
        gap: 10rem;
        margin-bottom: 2rem;
    }
    .footer-l-s>:nth-child(1){
        width: 150%;
        max-width: 32rem;
    }
    .footer-l-s>:nth-child(2){
        width: 100%;
        position: relative;
    }
    .footer-r-s{
        flex-direction: column;
        gap: 5rem;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .footer-r-s>:nth-child(1){
        width: 100%;
    }
    .footer-r-s>:nth-child(2){
        width: 100%;
    }
    .mail-box>input{
        border: none;
        padding: 1rem;
        font-size: 1rem;
    }
}